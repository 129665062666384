import { TextField } from "@mui/material";
import React from "react";

const CustomTextField = ({ value, setValue, regexError,disabled, type }) => {
    return (
        <TextField
            fullWidth
            variant="outlined"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            //  onChange={(e) => setValue(e.target.value.trim())}
            error={regexError ? true : false}
            helperText={regexError}
            disabled={disabled}
            type={type}
            sx={{  '& input': { height: '22px', padding: '5px 10px', fontSize:"12px" } }}
        />
    );
};

export default CustomTextField;
