// Import necessary libraries
import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, TextField, Typography, Autocomplete, Paper, ListItemText, Checkbox, FormControlLabel } from '@mui/material';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the plugin
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { cptGraph, getAllVisitReasons } from '../../../../src/api/action';
const EncountersByCpt = () => {
    // Bar chart ref
    const chartRef = useRef(null);
    const [dateRange, setDateRange] = useState([null, null]); // Array to store start and end dates
    const [startDate, endDate] = dateRange;
    const [data, setData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [loading, setLoading] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch = useDispatch();

    const adjustToLocalDate = (date) => {
        if (date === null) return null;
        const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        return adjustedDate.toISOString().split('T')[0];
    };

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllVisitReasons(payload,
                (data) => {
                    console.log(data?.data?.Data)
                    setLoading(false);
                    setData(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [])

    useEffect(() => {
        setLoading(true)
        const payload = {
            // facility_type: selectedOption?.id,
            provider_profile_id: localStorage.getItem("profileId"),
            start_date: adjustToLocalDate(startDate),
            end_date: adjustToLocalDate(endDate)
        };
        dispatch(
            cptGraph(payload,
                (data) => {
                    console.log(data?.data)
                    setLoading(false);
                    setGraphData(data?.data[0])
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [selectedOption, endDate])



    // Chart data
    const chartData = {
        labels: graphData.map(item => item.cpt_code__visit_reason_code),
        datasets: [
            {
                // label: 'Data Set 1',
                marginTop: 500,
                data: graphData.map(item => item.total),
                backgroundColor: '#6ab187',
                borderColor: '#6ab187',
                borderWidth: 2,
                hoverBackgroundColor: '#6ab187',
                hoverBorderColor: '#6ab187',
                datalabels: {
                    color: '#6ab187',
                    anchor: 'end',
                    align: 'end',
                    formatter: (value) => value,
                },
                barThickness: 25,
            },
        ],
    };

    // Initialize or update chart
    useEffect(() => {
        if (!chartRef.current) {
            const maxDataValue = Math.max(...graphData.map(item => item.total));
            const dynamicStepSize = Math.ceil(maxDataValue / 5);
            const suggestedMax = Math.ceil(maxDataValue / dynamicStepSize) * dynamicStepSize + dynamicStepSize;

            chartRef.current = new Chart(document.getElementById('myBarChart4').getContext('2d'), {
                type: 'bar',
                data: chartData,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                            position: 'top',
                            labels: {
                                // color: '#333',
                                font: {
                                    size: 14,
                                    weight: 'normal',
                                },
                            },
                        },
                        tooltip: {
                            backgroundColor: '#6ab187',
                            titleFont: { size: 12, weight: 400 },
                            bodyFont: { size: 14 },
                            bodyColor: '#fff',
                            borderColor: '#fff',
                            borderWidth: 1,
                        },
                        datalabels: {
                            color: '#6ab187',
                            font: {
                                weight: 'bold',
                            },
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                            title: {
                                display: true,
                                text: 'CPT Codes',
                                color: '#333',
                                font: {
                                    size: 12,
                                    weight: 'bold',
                                },
                            },
                            ticks: {
                                color: '#333',
                            },
                            categoryPercentage: 0.8,
                        },
                        y: {
                            // marginTop:'100px',
                            grid: {
                                color: '#ddd',
                            },
                            title: {
                                display: true,
                                text: 'No. of Encounters',
                                color: '#333',
                                font: {
                                    size: 12,
                                    weight: 'bold',
                                },
                            },
                            ticks: {
                                beginAtZero: true,
                                color: '#333',
                                stepSize: dynamicStepSize,
                            },
                            suggestedMax: suggestedMax,
                        },
                    },
                },
                plugins: [ChartDataLabels],
            });
        } else {
            chartRef.current.data = chartData;
            chartRef.current.update();
        }

        // Cleanup
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
                chartRef.current = null;
            }
        };
    }, [chartData]);

    console.log("selectedOption", selectedOption)
    console.log("selectedOption", dateRange)

    return (
        <Paper sx={{ p: 5, bgcolor: '#f9f9f9' }}>
            <Grid container spacing={4}>


                {/* Autocomplete */}
                {/* <Grid item xs={6}>
                    <Autocomplete
                        multiple // Enable multiple selection
                        disableCloseOnSelect
                        value={selectedOption?.map(id => data.find(option => option.id === id))} // Map IDs back to objects for display
                        onChange={(event, newValue) => {
                            setSelectedOption(newValue.map(option => option.id)); // Store only IDs
                        }}
                        sx={{
                            height: '10px !important',
                            '& .MuiAutocomplete-inputRoot': {
                                height: '10px', // Set height for the entire Autocomplete container
                                padding: '3px', // Remove padding
                            },
                            '& .MuiOutlinedInput-root': {
                                height: '10px', // Ensure the outlined input also has the desired height
                                padding: '3px',
                            },
                        }}
                        options={data}
                        getOptionLabel={(option) => option.visit_reason_code} // Use the name for display
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.id === value.id} // Comparison for selected value
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <FormControlLabel
                                    sx={{ fontSize: "10px !important" }}
                                    control={
                                        <Checkbox
                                            sx={{
                                                transform: 'scale(0.75)',
                                                '& .MuiSvgIcon-root': { fontSize: 22 },
                                            }}
                                            checked={selected}
                                        />
                                    }
                                    label={
                                        <ListItemText
                                            sx={{
                                                '& .MuiTypography-root': { fontSize: '12px !important' }
                                            }}
                                            primary={`${option.visit_reason_code}`}
                                        />
                                    }
                                />
                            </li>
                        )}
                    />
                </Grid> */}

                <Grid item xs={12}>
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        selected={startDate}
                        onChange={(dates) => setDateRange(dates)} // Save the selected range in state
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        isClearable
                        customInput={<TextField fullWidth className='width-100' sx={{ background: "#fff", }} />}
                        className='width-100'
                        placeholderText="Select date range"
                    />
                </Grid>

                {/* Bar Chart */}
                <Grid item xs={12}>
                    <Paper elevation={0} sx={{ borderRadius: '16px', p: 3 }}>
                        <Typography variant="body1" gutterBottom align="center" sx={{ color: '#6ab187' }}>
                            Encounters by CPT Code
                        </Typography>
                        <Box sx={{ height: '300px', bgcolor: '#fff', borderRadius: '16px' }}>
                            <canvas id="myBarChart4"></canvas>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default EncountersByCpt;
