// Import necessary libraries
import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, TextField, Typography, Autocomplete, Paper, ListItemText, Checkbox, FormControlLabel, Chip, InputLabel } from '@mui/material';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the plugin
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { facilityGraph, faciltyTypeGraph, getAllFacility, getAllFacilityTypes, getAllProviders, providerGraph, providerListByFacility } from '../../../../api/action';
const EncountersByProvider = () => {
    // Bar chart ref
    const chartRef = useRef(null);
    const [dateRange, setDateRange] = useState([null, null]); // Array to store start and end dates
    const [startDate, endDate] = dateRange;
    const [data, setData] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [loading, setLoading] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOption1, setSelectedOption1] = useState(null);
    const dispatch = useDispatch();

    const adjustToLocalDate = (date) => {
        if (date === null) return null;
        const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        return adjustedDate.toISOString().split('T')[0];
    };

    useEffect(() => {
        setLoading(true);
        const payload = null;
        dispatch(
            getAllFacility(payload,
                (data) => {
                    setLoading(false);
                    setFacilities(data?.data?.Data);
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            )
        );
    }, [dispatch]);

    // useEffect(() => {
    //     setLoading(true)
    //     const payload = null;
    //     dispatch(
    //         getAllProviders(payload,
    //             (data) => {
    //                 console.log(data?.data, "abc")
    //                 setLoading(false);
    //                 setData(data?.data?.Data)
    //             },
    //             (error) => {
    //                 console.log(error)
    //                 setLoading(false)
    //             }
    //         )
    //     );

    // }, [])

    useEffect(() => {
        // setLoading(true)
        const payload = null
        const id = selectedOption1 ? selectedOption1?.id : "null"
        dispatch(
            providerListByFacility(payload, id,
                (data) => {
                    setLoading(false);
                    setData(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [selectedOption1])

    useEffect(() => {
        setLoading(true)
        const payload = {
            "facility_id":selectedOption1?.id,
            "provider_id":selectedOption?.id
        };
        dispatch(
            providerGraph(payload,
                (data) => {
                    setLoading(false);
                    setGraphData(data?.data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [selectedOption])



    // Chart data
    const chartData = {
        labels: graphData?.map(item => item?.month),
        datasets: [
            {
                // label: 'Data Set 1',
                marginTop: 500,
                data: graphData?.map(item => item.count),
                backgroundColor: '#E6B5B5',
                borderColor: '#E6B5B5',
                borderWidth: 2,
                hoverBackgroundColor: '#E6B5B5',
                hoverBorderColor: '#E6B5B5',
                datalabels: {
                    color: '#AC3131',
                    anchor: 'end',
                    align: 'end',
                    formatter: (value) => value,
                },
                barThickness: 25,
            },
        ],
    };

    // Initialize or update chart
    useEffect(() => {
        if (!chartRef.current) {
            const maxDataValue = Math.max(...graphData?.map(item => item.count));
            const dynamicStepSize = Math.ceil(maxDataValue / 5);
            const suggestedMax = Math.ceil(maxDataValue / dynamicStepSize) * dynamicStepSize + dynamicStepSize;

            chartRef.current = new Chart(document.getElementById('myBarChart3').getContext('2d'), {
                type: 'bar',
                data: chartData,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                            position: 'top',
                            labels: {
                                // color: '#333',
                                font: {
                                    size: 14,
                                    weight: 'normal',
                                },
                            },
                        },
                        tooltip: {
                            backgroundColor: '#AC3131',
                            titleFont: { size: 14, weight: 400 },
                            bodyFont: { size: 14 },
                            bodyColor: '#fff',
                            borderColor: '#AC3131',
                            borderWidth: 1,
                        },
                        datalabels: {
                            color: '#AC3131',
                            font: {
                                weight: 'bold',
                            },
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                            title: {
                                display: true,
                                text: 'Months',
                                color: '#333',
                                font: {
                                    size: 12,
                                    weight: 'bold',
                                },
                            },
                            ticks: {
                                color: '#333',
                            },
                            categoryPercentage: 0.8,
                        },
                        y: {
                            // marginTop:'100px',
                            grid: {
                                color: '#ddd',
                            },
                            title: {
                                display: true,
                                text: 'No. of Encounters',
                                color: '#333',
                                font: {
                                    size: 12,
                                    weight: 'bold',
                                },
                            },
                            ticks: {
                                beginAtZero: true,
                                color: '#333',
                                stepSize: dynamicStepSize,
                            },
                            suggestedMax: suggestedMax,
                        },
                    },
                },
                plugins: [ChartDataLabels],
            });
        } else {
            chartRef.current.data = chartData;
            chartRef.current.update();
        }

        // Cleanup
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
                chartRef.current = null;
            }
        };
    }, [chartData]);

    return (
        <Paper sx={{ p: 5, bgcolor: '#f9f9f9' }}>
            <Grid container spacing={2}>
            <Grid item md={12} xs={6}>
                    {/* <InputLabel id="facility-name-label">Facility Name</InputLabel> */}
                    <Autocomplete
                        className='scrollBar'
                        fullWidth
                        value={selectedOption1}
                        onChange={(event, newValue) => {
                            setSelectedOption1(newValue);
                            setSelectedOption(null);
                            // setCurrentPage(1)
                        }}
                        sx={{
                            // maxHeight: 56,
                            // height: 56,
                            padding: 0,
                            fontSize: "10px",
                            overflowY: 'hidden',
                            '& .MuiAutocomplete-inputRoot': {
                                flexWrap: 'nowrap', // Prevent wrapping of chips
                                fontSize: '10px',
                                // padding: 0,
                                px: 0,
                                py: 0
                            },
                            '& .MuiAutocomplete-tag': {
                                overflow: 'hidden',
                                padding: 0,

                            }
                        }}
                        // clearIcon={null}
                        options={facilities} // Pass the array directly
                        getOptionLabel={(option) => option.facility_name}
                        getOptionSelected={(option, value) => option.facility_name === value.facility_name}
                        isOptionEqualToValue={(option, value) => option.facility_name === value.facility_name}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            fullWidth
                            placeholder="Select Facility"
                            sx={{
                                background: "#fff",
                                '& input': {
                                    height: "17px",
                                    padding: '0px',
                                    fontSize: '12px',
                                    overflowY: 'scroll',
                                    '&::-webkit-scrollbar': {
                                        display: 'none',
                                    },
                                },
                            }}
                        />
                        )}
                    />
                </Grid>

                {/* Autocomplete */}
                <Grid item xs={12}>
                    <Autocomplete
                        limitTags={1}
                        disabled={!selectedOption1}
                        // multiple 
                        // disableCloseOnSelect
                        value={selectedOption} // Map IDs back to objects for display
                        onChange={(event, newValue) => {
                            setSelectedOption(newValue); // Store only IDs
                        }}
                        // value={selectedOption?.map(id => data?.find(option => option.id === id))} // Map IDs back to objects for display
                        // onChange={(event, newValue) => {
                        //     setSelectedOption(newValue.map(option => option.id)); // Store only IDs
                        // }}
                        sx={{
                            // maxHeight: 56,
                            // height: 56,
                            padding: 0,
                            fontSize: "10px",
                            overflowY: 'hidden',
                            '& .MuiAutocomplete-inputRoot': {
                                flexWrap: 'nowrap', // Prevent wrapping of chips
                                fontSize: '10px',
                                // padding: 0,
                                px: 0,
                                py: 0
                            },
                            '& .MuiAutocomplete-tag': {
                                overflow: 'hidden',
                                padding: 0,

                            }
                        }}
                        ListboxProps={{
                            style: {
                                maxHeight: '150px',
                                overflowY: 'auto',
                            }
                        }}
                        // renderTags={(value, getTagProps) =>
                        //     value.map((option, index) => {
                        //         const { key, ...tagProps } = getTagProps({ index });
                        //         return (
                        //             <Chip
                        //                 key={key}
                        //                 //   variant="outlined"
                        //                 label={`${option.First_Name} ${option.Last_Name}`} // Use `facility_name` from your options
                        //                 size="small"
                        //                 sx={{ fontSize: '10px', margin: 0, paddingRight: '0px', width: '7rem', height: '20px' }}
                        //                 {...tagProps}
                        //             />
                        //         );
                        //     })
                        // }
                        options={data}
                        getOptionLabel={(option) => option.First_Name + " " + option.Last_Name} // Use the name for display
                        getOptionDisabled={(option) => selectedOption?.length >= 3 && !selectedOption?.includes(option.id)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                placeholder="Select Provider"
                                sx={{
                                    background: "#fff",
                                    '& input': {
                                        height: "17px",
                                        padding: '0px',
                                        fontSize: '12px',
                                        overflowY: 'scroll',
                                        '&::-webkit-scrollbar': {
                                            display: 'none',
                                        },
                                    },
                                }}
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.id === value.id} // Comparison for selected value
                        // renderOption={(props, option, { selected }) => (
                        //     <li {...props}>
                        //         <FormControlLabel
                        //             sx={{ fontSize: "10px !important" }}
                        //             control={
                        //                 <Checkbox
                        //                     sx={{
                        //                         transform: 'scale(0.75)',
                        //                         '& .MuiSvgIcon-root': { fontSize: 22 },
                        //                     }}
                        //                     checked={selected}
                        //                 />
                        //             }
                        //             label={
                        //                 <ListItemText
                        //                     sx={{
                        //                         '& .MuiTypography-root': { fontSize: '12px !important' }
                        //                     }}
                        //                     primary={`${option.First_Name} ${option.Last_Name}`}
                        //                 />
                        //             }
                        //         />
                        //     </li>
                        // )}
                    />
                </Grid>

                {/* <Grid item xs={6}>
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        selected={startDate}
                        onChange={(dates) => setDateRange(dates)} // Save the selected range in state
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        isClearable
                        customInput={<TextField fullWidth className='width-100' sx={{ background: "#fff", }} placeholder="Select date range" />}
                        className='width-100'
                        placeholderText="Select date range"
                    />
                </Grid> */}

                {/* Bar Chart */}
                <Grid item xs={12}>
                    <Paper elevation={0} sx={{ borderRadius: '16px', px: 3, py: 3 }}>
                        <Typography variant="body1" gutterBottom align="center" sx={{ color: '#AC3131' }}>
                            Encounters by Providers
                        </Typography>
                        <Box sx={{ height: '300px', bgcolor: '#fff', borderRadius: '16px' }}>
                            <canvas id="myBarChart3"></canvas>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default EncountersByProvider;
