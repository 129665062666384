// Import necessary libraries
import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, TextField, Typography, Autocomplete, Paper } from '@mui/material';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the plugin
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import { faciltyTypeGraph, getAllFacilityTypes } from '../../../../api/action';

const EncountersByFaciltyType = () => {
    const chartRef = useRef(null);
    const [dateRange, setDateRange] = useState([null, null]); // Array to store start and end dates
    const [startDate, endDate] = dateRange;
    const [data, setData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [loading, setLoading] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch = useDispatch();

    const adjustToLocalDate = (date) => {
        if (date === null) return null;
        const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        return adjustedDate.toISOString().split('T')[0];
    };

    useEffect(() => {
        setLoading(true);
        const payload = null;
        dispatch(
            getAllFacilityTypes(payload,
                (data) => {
                    setLoading(false);
                    setData(data?.data?.Data);
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            )
        );
    }, [dispatch]);

    useEffect(() => {
        setLoading(true);
        const payload = {
            facility_type: selectedOption === null ? "" : [selectedOption?.id],
            start_date: startDate === null ? "" : adjustToLocalDate(startDate),
            end_date: endDate === null ? "" : adjustToLocalDate(endDate),
        };
        dispatch(
            faciltyTypeGraph(payload,
                (data) => {
                    setLoading(false);
                    setGraphData(data?.data);
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            )
        );
    }, [selectedOption, endDate, startDate, dispatch]);

    // Chart data for Pie chart
    const chartData = {
        labels: graphData.map(item => item.facility_type_name),
        datasets: [
            {
                data: graphData.map(item => item.total),
                backgroundColor: [
                    '#4CB5F5', '#CED2CC', '#FFD700', '#8E44AD', '#007B89', '#00C3E8'
                ], // Attractive color palette
                borderWidth: 0, // Remove border lines between slices
                hoverBackgroundColor: [
                    '#4CB5F5', '#CED2CC', '#FFC107', '#9B59B6', '#0093AF', '#00B2D8'
                ], // On hover colors
            },
        ],
    };
    
    // Initialize or update chart
    useEffect(() => {
        if (!chartRef.current) {
            chartRef.current = new Chart(document.getElementById('myPieChart').getContext('2d'), {
                type: 'pie',
                data: chartData,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                font: {
                                    size: 14,
                                    weight: 400,
                                },
                                color: '#333',
                            },
                        },
                        tooltip: {
                            backgroundColor: '#4CB5F5',
                            titleFont: { size: 12, weight: 'bold' },
                            bodyFont: { size: 12 },
                            bodyColor: '#fff',
                            borderColor: '#fff',
                            borderWidth: 1,
                        },
                        datalabels: {
                            color: '#fff',
                            font: {
                                size: 12,
                                weight: 'bold',
                            },
                            formatter: (value) => {
                                return value; 
                            },
                        },
                    },
                    animation: {
                        animateScale: true,
                        animateRotate: true,
                    },
                },
                plugins: [ChartDataLabels],
            });
        } else {
            chartRef.current.data = chartData;
            chartRef.current.update();
        }
    
        // Cleanup
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
                chartRef.current = null;
            }
        };
    }, [chartData]);

    return (
        <Paper sx={{ p: 5, bgcolor: '#f9f9f9', }}>
            <Grid container spacing={4}>

                {/* Autocomplete */}
                <Grid item xs={6}>
                    <Autocomplete
                        value={selectedOption}
                        onChange={(event, newValue) => setSelectedOption(newValue)}
                        options={data}
                        getOptionLabel={(option) => option.facility_type_Name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                placeholder="Select Facilty Type"
                                sx={{ background: "#fff", }}
                            />
                        )}
                        sx={{
                            height: '10px',
                            background: "#fff",
                            '& .MuiAutocomplete-inputRoot': {
                                height: '10px', // Set height for the entire Autocomplete container
                                padding: '3px', // Remove padding
                            },
                            '& .MuiOutlinedInput-root': {
                                height: '10px', // Ensure the outlined input also has the desired height
                                padding: '3px',
                            },
                        }}
                    />
                </Grid>

                {/* DatePicker */}
                <Grid item xs={6}>
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        selected={startDate}
                        onChange={(dates) => setDateRange(dates)}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        isClearable
                        placeholderText="Select date range"
                        customInput={<TextField fullWidth sx={{ background: "#fff", }} />}
                    />
                </Grid>

                {/* Pie Chart */}
                <Grid item xs={12}>
                <Paper elevation={0} sx={{ borderRadius: '16px', p: 3 }}>
                        <Typography variant="body1" align="center" sx={{ color: '#4CB5F5', }}>
                            Encounters by Facility Type
                        </Typography>
                        <Box sx={{ height: '300px', bgcolor: '#fff', borderRadius: '16px', }}>
                            <canvas id="myPieChart"></canvas>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default EncountersByFaciltyType;
