// Import necessary libraries
import React, { useEffect, useRef } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Chart from 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import Layout from '../../Layout';
import EncountersByFaciltyType from './Components/Graph/EncountersByFaciltyType';
import EncountersByFacility from './Components/Graph/EncountersByFacility';
import EncountersByProvider from './Components/Graph/EncountersByProvider';
import EncountersByCpt from './Components/Graph/EncountersByCpt';

const Dashboard = () => {
 

  return (
    <Layout>
      <Grid container >
      <Grid  item md={6} xs={12} sx={{ textAlign: 'center', p:2 }}>
          <EncountersByFaciltyType />
        </Grid>
        <Grid item md={6} xs={12} sx={{ textAlign: 'center', p:2}}>
          <EncountersByFacility />
        </Grid>
        <Grid item md={6} xs={12} sx={{ textAlign: 'center', p:2}}>
          <EncountersByProvider />
        </Grid>
        <Grid item md={6} xs={12} sx={{ textAlign: 'center', p:2}}>
          <EncountersByCpt />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Dashboard;
