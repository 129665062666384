// Import necessary libraries
import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, TextField, Typography, Autocomplete, Paper, ListItemText, Checkbox, FormControlLabel, Chip } from '@mui/material';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the plugin
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { facilityGraph, getAllFacility, getFacilityByProviderId, providerFacilityGraph } from '../../../../src/api/action';
const FacilityGraph = () => {
    // Bar chart ref
    const chartRef = useRef(null);
    const [dateRange, setDateRange] = useState([null, null]); // Array to store start and end dates
    const [startDate, endDate] = dateRange;
    const [data, setData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [loading, setLoading] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch = useDispatch();

    const adjustToLocalDate = (date) => {
        if (date === null) return null;
        const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        return adjustedDate.toISOString().split('T')[0];
    };

    useEffect(() => {
        setLoading(true)
        const payload = null;
        const providerId = localStorage.getItem("userId")
        dispatch(
            getFacilityByProviderId(payload, providerId,
                (data) => {
                    console.log(data?.data?.Data)
                    setLoading(false);
                    setData(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [])

    useEffect(() => {
        setLoading(true)
        const payload = {
            facility_id: selectedOption === null ? "" : selectedOption?.id,
            // start_date: startDate === null ? "" : adjustToLocalDate(startDate),
            // end_date: endDate === null ? "" : adjustToLocalDate(endDate),
            provider_id: localStorage.getItem("profileId"),
        };
        dispatch(
            providerFacilityGraph(payload,
                (data) => {
                    console.log(data?.data)
                    setLoading(false);
                    setGraphData(data?.data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [selectedOption, endDate])



    // Chart data
    const chartData = {
        labels: graphData.map(item => item.month),
        datasets: [
            {
                // label: 'Data Set 1',
                marginTop: 500,
                data: graphData.map(item => item.count),
                backgroundColor: '#4bc0c0',
                borderColor: '#4bc0c0',
                borderWidth: 2,
                hoverBackgroundColor: '#4bc0c0',
                hoverBorderColor: '#4bc0c0',
                datalabels: {
                    color: '#4bc0c0',
                    anchor: 'end',
                    align: 'end',
                    formatter: (value) => value,
                },
                barThickness: 25,
            },
        ],
    };

    // Initialize or update chart
    useEffect(() => {
        if (!chartRef.current) {
            const maxDataValue = Math.max(...graphData.map(item => item.count));
            const dynamicStepSize = Math.ceil(maxDataValue / 10);
            const suggestedMax = Math.ceil(maxDataValue / dynamicStepSize) * dynamicStepSize + dynamicStepSize;

            chartRef.current = new Chart(document.getElementById('myBarChart2').getContext('2d'), {
                type: 'bar',
                data: chartData,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                            position: 'top',
                            labels: {
                                // color: '#333',
                                font: {
                                    size: 14,
                                    weight: 'normal',
                                },
                            },
                        },
                        tooltip: {
                            backgroundColor: '#4bc0c0',
                            titleFont: { size: 12, weight: 400 },
                            bodyFont: { size: 14 },
                            bodyColor: '#fff',
                            borderColor: '#fff',
                            borderWidth: 1,
                        },
                        datalabels: {
                            color: '#4bc0c0',
                            font: {
                                weight: 'bold',
                            },
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                            title: {
                                display: true,
                                text: 'Months',
                                color: '#333',
                                font: {
                                    size: 12,
                                    weight: 'bold',
                                },
                            },
                            ticks: {
                                color: '#333',
                                // Rotate the labels vertically
                                rotation: 90,
                                align: 'start',
                                crossAlign: 'center',
                            },
                            categoryPercentage: 0.8,
                        },
                        y: {
                            // marginTop:'100px',
                            grid: {
                                color: '#ddd',
                            },
                            title: {
                                display: true,
                                text: 'No. of Encounters',
                                color: '#333',
                                font: {
                                    size: 12,
                                    weight: 'bold',
                                },
                            },
                            ticks: {
                                beginAtZero: true,
                                color: '#333',
                                stepSize: dynamicStepSize,
                            },
                            suggestedMax: suggestedMax,
                        },
                    },
                },
                plugins: [ChartDataLabels],
            });
        } else {
            chartRef.current.data = chartData;
            chartRef.current.update();
        }

        // Cleanup
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
                chartRef.current = null;
            }
        };
    }, [chartData]);

    return (
        <Paper sx={{ p: 5, bgcolor: '#f9f9f9' }}>
            <Grid container spacing={4}>


                {/* Autocomplete */}
                <Grid item xs={12}>

                    <Autocomplete
                        // multiple // Enable multiple selection
                        // limitTags={1}
                        // disableCloseOnSelect
                        value={selectedOption} // Map IDs back to objects for display
                        onChange={(event, newValue) => {
                            setSelectedOption(newValue); // Store only IDs
                        }}
                        sx={{
                            // maxHeight: 48, 
                            overflowY: 'hidden',
                            '& .MuiAutocomplete-inputRoot': {
                                flexWrap: 'nowrap', // Prevent wrapping of chips
                                px:0,
                                py:0
                            },
                            '& .MuiAutocomplete-tag': {
                                overflow: 'hidden',
                                // textOverflow: 'ellipsis',
                                // whiteSpace: 'nowrap',
                            }
                        }}
                        ListboxProps={{
                            style: {
                                maxHeight: '150px', 
                                overflowY: 'auto',
                            }
                        }}

                        // renderTags={(value, getTagProps) =>
                        //     value.map((option, index) => {
                        //         const { key, ...tagProps } = getTagProps({ index });
                        //         return (
                        //             <Chip
                        //                 key={key}
                        //                 //   variant="outlined"
                        //                 label={option.facility_name} // Use `facility_name` from your options
                        //                 size="small"
                        //                 sx={{ fontSize: '10px',  margin:0, paddingRight:'0px', width:'7rem', height:'20px'}}
                        //                 {...tagProps}
                        //             />
                        //         );
                        //     })
                        // }

                        options={data}
                        getOptionLabel={(option) => option.facility_name} // Use the name for display
                        getOptionDisabled={(option) => selectedOption?.length >= 3 && !selectedOption?.includes(option.id)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                 placeholder="Select Facilty"
                                sx={{
                                    background: "#fff",
                                    '& input': {
                                        height:"17px",
                                        padding: '0px',
                                        fontSize: '10px',
                                        overflowY: 'scroll',
                                        '&::-webkit-scrollbar': {
                                            display: 'none',
                                        },
                                    },
                                }}
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.id === value.id} // Comparison for selected value
                        // renderOption={(props, option, { selected }) => (
                        //     <li {...props}>
                        //         <FormControlLabel
                        //             sx={{ fontSize: "10px !important" }}
                        //             control={
                        //                 <Checkbox
                        //                     sx={{
                        //                         transform: 'scale(0.75)',
                        //                         '& .MuiSvgIcon-root': { fontSize: 22 },
                        //                     }}
                        //                     checked={selected}
                        //                 />
                        //             }
                        //             label={
                        //                 <ListItemText
                        //                     sx={{
                        //                         '& .MuiTypography-root': { fontSize: '12px !important' }
                        //                     }}
                        //                     primary={`${option.facility_name}`}
                        //                 />
                        //             }
                        //         />
                        //     </li>
                        // )}
                    />
                </Grid>

                {/* <Grid item xs={6}>
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        selected={startDate}
                        onChange={(dates) => setDateRange(dates)} // Save the selected range in state
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        isClearable
                        customInput={<TextField fullWidth className='width-100' sx={{ background: "#fff", }} />}
                        className='width-100'
                        placeholderText="Select date range"
                    />
                </Grid> */}

                {/* Bar Chart */}
                <Grid item xs={12}>
                    <Paper elevation={0} sx={{ borderRadius: '16px', p: 3 }}>
                        <Typography variant="body1" gutterBottom align="center" sx={{ color: '#4bc0c0' }}>
                            Encounters by Facility
                        </Typography>
                        <Box sx={{ height: '300px', bgcolor: '#fff', borderRadius: '16px' }}>
                            <canvas id="myBarChart2"></canvas>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default FacilityGraph;
