import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  InputLabel,
  Grid,
  IconButton,
  Menu,
  CircularProgress,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getAllFacility,
  getAllFacilityTypes,
  getAllProviders,
  getFacilityByProviderId,
  getFacilityTypeByProviderId,
  getSuperBillByAdmin,
  providerListByFacility,
} from "../../../api/action";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import CustomPagination from "../../../Utils/CustomPagination";
import CustomTextField from "../../../Utils/CustomTextfield";

const AdminReportBody = ({ onEdit, onDelete }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchFacilityCode, setSearchFacilityCode] = useState("");
  const [searchFacilityName, setSearchFacilityName] = useState("");
  // const [searchFacilityType, setSearchFacilityType] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [providerList, setProviderList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [facilityTypeList, setFacilityTypeList] = useState([]);
  const [searchProvider, setSearchProvider] = useState(null);
  const [searchFacility, setSearchFacility] = useState(null);
  const [searchFacilityType, setSearchFacilityType] = useState(null);
  const [status, setStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchFirstName, setSearchFirstName] = useState("");
  const [searchLastName, setSearchLastName] = useState("");
  const [startDate, endDate] = dateRange;
  const [filteredData, setFilteredData] = useState([]);
  const [counts, setCounts] = useState("");
  const [search, setSearch] = useState(false);
  const [previousPayload, setPreviousPayload] = useState(null);

  const dispatch = useDispatch();

  const handleCheckboxChange = (facilityCode) => {
    const isSelected = selectedRows.includes(facilityCode);
    setSelectedRows((prevSelected) =>
      isSelected
        ? prevSelected.filter((code) => code !== facilityCode)
        : [...prevSelected, facilityCode]
    );
  };

  const handleEditClick = (event, selectedFacility) => {
    setAnchorEl(event.currentTarget);
    setSelectedFacility(selectedFacility);
  };

  const handleEditClose = () => {
    setAnchorEl(null);
  };

  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11
    const day = String(date.getDate()).padStart(2, "0"); // getDate() returns the day of the month
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleSearch = () => {
    setLoading(true);
    const payload = {
      provider_profile: searchProvider?.id,
      facility_master: searchFacility?.id,
      facility_type: searchFacilityType?.id,
      patient_name: searchFirstName,
      last_name: searchLastName,
      is_status: status,
      date_from: startDate ? formatDate(startDate) : null,
      date_to: endDate ? formatDate(endDate) : null,
      download_excel: false,
    };

    // const isPayloadChanged = (currentPayload, previousPayload) => {
    //     return JSON.stringify(currentPayload) !== JSON.stringify(previousPayload);
    // };

    // // Determine the current page
    // const updatedPage = isPayloadChanged(payload, previousPayload) ? 1 : currentPage;

    dispatch(
      getSuperBillByAdmin(
        payload,
        currentPage,
        (data) => {
          setLoading(false);
          // setData(data?.data)
          setFilteredData(search === true ? data?.data?.results : []);
          setCounts(data?.data?.count);
          setSelectAll(false);
          setSelectedRows([]);

          // setFilteredData(data?.data)
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      )
    );

    // setCurrentPage(1);
  };

  useEffect(() => {
    handleSearch();
    setSearch(true);
  }, [currentPage]);

  // useEffect(() => {
  //     setLoading(true)
  //     const payload = {
  //         "provider_profile": 3,
  //         "facility_master": searchFacility,
  //         "facility_type": searchFacilityType,
  //         "patient_name": searchFirstName,
  //         "last_name": searchLastName,
  //         "is_status": status,
  //         "date_from": startDate,
  //         "date_to": endDate,
  //     }

  //     console.log("payload", payload)
  //     dispatch(
  //         getSuperBillByAdmin(payload,
  //             (data) => {
  //                 setLoading(false);
  //                 console.log(data)
  //                 setData(data?.data)
  //                 // setFilteredData(data?.data)
  //             },
  //             (error) => {
  //                 console.log(error)
  //                 setLoading(false)
  //             }
  //         )
  //     );

  // }, [updated])

  // useEffect(() => {
  //     setLoading(true)
  //     const payload = null;
  //     dispatch(
  //         getAllProviders(payload,
  //             (data) => {
  //                 setLoading(false);
  //                 setProviderList(data?.data?.Data)
  //             },
  //             (error) => {
  //                 console.log(error)
  //                 setLoading(false)
  //             }
  //         )
  //     );

  // }, [updated])

  useEffect(() => {
    // setLoading(true)
    const payload = null;
    const id = searchFacility ? searchFacility?.id : "null";
    dispatch(
      providerListByFacility(
        payload,
        id,
        (data) => {
          setLoading(false);
          setProviderList(data?.data?.Data);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      )
    );
  }, [searchFacility]);

  // useEffect(() => {

  //     if (searchProvider !== null) {
  //         setLoading(true);
  //         const payload = null;
  //         const providerId = searchProvider?.user_id;
  //         dispatch(
  //             getFacilityByProviderId(
  //                 payload,
  //                 providerId,
  //                 (data) => {
  //                     console.log(data?.data?.Data)
  //                     setLoading(false);
  //                     setFacilityList(data?.data?.Data)
  //                 },
  //                 (error) => {
  //                     console.log(error);
  //                     setLoading(false);
  //                 }
  //             )
  //         );
  //     }

  // }, [updated, searchProvider])

  useEffect(() => {
    setLoading(true);
    const payload = null;
    dispatch(
      getAllFacilityTypes(
        payload,
        (data) => {
          setLoading(false);
          setFacilityTypeList(data?.data?.Data);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      )
    );
  }, []);

  // useEffect(() => {
  //     if (searchProvider !== null) {
  //         setLoading(true);
  //         const payload = null;
  //         const providerId = searchFacility?.id;
  //         dispatch(
  //             getFacilityTypeByProviderId(
  //                 payload,
  //                 providerId,
  //                 (data) => {
  //                     console.log(data?.data?.Data)
  //                     setLoading(false);
  //                     setFacilityTypeList(data?.data?.Data)
  //                 },
  //                 (error) => {
  //                     console.log(error);
  //                     setLoading(false);
  //                 }
  //             )
  //         );
  //     }

  // }, [updated, searchFacility])

  useEffect(() => {
    setLoading(true);
    const payload = null;
    dispatch(
      getAllFacility(
        payload,
        (data) => {
          setLoading(false);
          setFacilityList(data?.data?.Data);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      )
    );
  }, []);

  const handleEdit = (newStatus) => {
    if (selectedFacility) {
      const updatedFacility = {
        ...selectedFacility,
        status: newStatus,
      };

      // Update the status in the data array
      const updatedData = data.map((facility) =>
        facility.facilityCode === selectedFacility.facilityCode
          ? updatedFacility
          : facility
      );

      // Trigger a re-render by updating the data state
      setData(updatedData);
      onEdit(updatedFacility);
      setAnchorEl(null);
    }
  };

  const handleDelete = () => {
    onDelete(selectedRows);
    setSelectedRows([]);
  };

  // useEffect(() => {
  //     setCurrentPage(1)
  // }, [searchProvider, searchFacility, searchFacility, searchFacilityType, searchFirstName, searchLastName, dateRange])

  // const filteredData = data && data.length !== 0 ? data.filter((row) => {
  //     const providerFullName = searchProvider ? `${searchProvider.First_Name} ${searchProvider.Last_Name}` : '';
  //     const facilityName = searchFacility ? searchFacility.facility_name : '';
  //     const facilityType = searchFacilityType ? searchFacilityType.facility_type_Name : '';
  //     const matchesFirstName = row.patient_name.toLowerCase().includes(searchFirstName.toLowerCase());
  //     const matchesLastName = row.last_name.toLowerCase().includes(searchLastName.toLowerCase());

  //     const matchesProvider = !searchProvider || (`${row.provider_profile?.First_Name} ${row.provider_profile?.Last_Name}` === providerFullName);
  //     const matchesFacility = !searchFacility || (row.facility_master?.facility_name === facilityName);
  //     const matchesFacilityType = !searchFacilityType || (row.facility_type?.facility_type_Name === facilityType);
  //     const matchesStatus = status === '' || row?.is_status.toLowerCase() === status.toLowerCase();
  //     const serviceDate = new Date(row.date_of_service);
  //     const matchesDateRange = (!startDate && !endDate) ||
  //         (startDate && endDate && serviceDate >= startDate && serviceDate <= endDate);

  //     return matchesProvider && matchesFacility && matchesFacilityType && matchesStatus && matchesDateRange && matchesFirstName && matchesLastName;
  // }) : [];

  // const handleSearch = () => {
  //     const filteredData = data && data.length !== 0 ? data.filter((row) => {
  //         const providerFullName = searchProvider ? `${searchProvider.First_Name} ${searchProvider.Last_Name}` : '';
  //         const facilityName = searchFacility ? searchFacility.facility_name : '';
  //         const facilityType = searchFacilityType ? searchFacilityType.facility_type_Name : '';
  //         const matchesFirstName = row.patient_name.toLowerCase().includes(searchFirstName.toLowerCase());
  //         const matchesLastName = row.last_name.toLowerCase().includes(searchLastName.toLowerCase());

  //         const matchesProvider = !searchProvider || (`${row.provider_profile?.First_Name} ${row.provider_profile?.Last_Name}` === providerFullName);
  //         const matchesFacility = !searchFacility || (row.facility_master?.facility_name === facilityName);
  //         const matchesFacilityType = !searchFacilityType || (row.facility_type?.facility_type_Name === facilityType);
  //         const matchesStatus = status === '' || row?.is_status.toLowerCase() === status.toLowerCase();
  //         const serviceDate = new Date(row.date_of_service);
  //         const matchesDateRange = (!startDate && !endDate) ||
  //             (startDate && endDate && serviceDate >= startDate && serviceDate <= endDate);

  //         return matchesProvider && matchesFacility && matchesFacilityType && matchesStatus && matchesDateRange && matchesFirstName && matchesLastName;
  //     }) : [];
  //     setFilteredData(filteredData);
  //     setCurrentPage(1); // Reset to the first page after search
  // };

  const handleClearSearch = () => {
    setDateRange([null, null]);
    setSearchProvider(null);
    setSearchFacility(null);
    setSearchFacilityType(null);
    setSearchFirstName("");
    setSearchLastName("");
    setStatus("");
    setFilteredData([]);
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      const allCodes = filteredData?.map((row) => row.id);
      setSelectedRows(allCodes);
      // Set all rows id in id state
      //   setId(allCodes);
    } else {
      setSelectedRows([]);
      //   setId([]);
    }
  };

  // useEffect(() => {
  //     if (searchFacility) {
  //         setSearchFacilityType(searchFacility?.facility_type)
  //     }

  // }, [searchFacility])

  const handleDownloadExcel = () => {
    if (selectAll === true) {
      setLoading(true);
      const payload = {
        provider_profile: searchProvider?.id,
        facility_master: searchFacility?.id,
        facility_type: searchFacilityType?.id,
        patient_name: searchFirstName,
        last_name: searchLastName,
        is_status: status,
        date_from: startDate ? formatDate(startDate) : null,
        date_to: endDate ? formatDate(endDate) : null,
        download_excel: selectAll,
      };

      dispatch(
        getSuperBillByAdmin(
          payload,
          currentPage,
          (data) => {
            setLoading(false);
            setData(data?.data);
            const exportData = data?.data.map((row) => ({
              // 'Facility Type': row.facility_type?.facility_type_Name,
              // 'State': row.state?.state_Name,
              Facility: row.facility_master?.facility_name,
              Facility: row.facility_master?.facility_name,
              "Provider First Name": row.provider_profile?.First_Name,
              "Provider Last Name": row.provider_profile?.Last_Name,

              // 'Provider': row.provider_profile?.First_Name + " " + row.provider_profile?.Last_Name,
              "Patient First Name": row.patient_name,
              "Patient Last Name": row.last_name,
              "Date of Birth": row.dob,
              "Date of Service": row.date_of_service,
              "CPT Code": row.cpt_code,
              "DX Code": row.dx_code,
              Modifier: row.modifier,
              Admission: row.admission,
              Comment: row.comment,
              "Reason for Visit": row.reason_for_visit,
              Status: row.is_status,
              Biller:
                row.biller_profile !== null
                  ? row.biller_profile?.First_Name +
                    " " +
                    row.biller_profile?.Last_Name
                  : "",
              "Submitted On": row?.submitted_on,
              Time: row?.time,
            }));

            // Create a new workbook

            // Create a new workbook
            const wb = XLSX.utils.book_new();

            // Convert data to worksheet
            const ws = XLSX.utils.json_to_sheet(exportData);

            // Convert data to worksheet
            const columnWidths = [
              // { wch: 20 }, // Width of Provider column
              // { wch: 20 }, // Width of Facility column
              { wch: 45 }, // Width of Facility Type column
              { wch: 15 }, // Width of State column
              { wch: 20 }, // Width of Patient Name column
              { wch: 20 }, // Width of Last Name column
              { wch: 15 }, // Width of Date of Birth column
              { wch: 15 }, // Width of Date of Service column
              { wch: 15 }, // Width of CPT Code column
              { wch: 15 }, // Width of DX Code column
              { wch: 15 }, // Width of Admission column
              { wch: 20 }, // Width of Reason for Visit column
              { wch: 20 }, // Width of Comment column
              { wch: 15 }, // Width of Modifier column
              { wch: 15 }, // Width of Status column
              { wch: 20 }, // Width of Biller column
            ];

            ws["!cols"] = columnWidths;

            // Add worksheet to workbook
            XLSX.utils.book_append_sheet(wb, ws, "SelectedRows");

            // Save the workbook as an Excel file
            XLSX.writeFile(wb, "Reports.xlsx");
            setSelectAll(false);
            setSelectedRows([]);
            // setFilteredData(data?.data)
          },
          (error) => {
            console.log(error);
            setLoading(false);
          }
        )
      );
    } else {
      const exportData = filteredData
        ?.filter((row) => selectedRows.includes(row.id))
        .map((row) => ({
          "Facility Type": row.facility_type?.facility_type_Name,
          // 'State': row.state?.state_Name,
          Facility: row.facility_master?.facility_name,
          // 'Facility': row.facility_master?.facility_name,
          "Provider First Name": row.provider_profile?.First_Name,
          "Provider Last Name": row.provider_profile?.Last_Name,

          // 'Provider': row.provider_profile?.First_Name + " " + row.provider_profile?.Last_Name,
          "Patient First Name": row.patient_name,
          "Patient Last Name": row.last_name,
          "Date of Birth": row.dob,
          "Date of Service": row.date_of_service,
          "CPT Code": row.cpt_code,
          "DX Code": row.dx_code,
          Modifier: row.modifier,
          Admission: row.admission,
          Comment: row.comment,
          "Reason for Visit": row.reason_for_visit,
          Status: row.is_status,
          Biller:
            row.biller_profile !== null
              ? row.biller_profile?.First_Name +
                " " +
                row.biller_profile?.Last_Name
              : "",
          "Submitted On": row?.submitted_on,
          Time: row?.time,
        }));

      // Create a new workbook

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Convert data to worksheet
      const ws = XLSX.utils.json_to_sheet(exportData);

      // Convert data to worksheet
      const columnWidths = [
        // { wch: 20 }, // Width of Provider column
        // { wch: 20 }, // Width of Facility column
        { wch: 45 }, // Width of Facility Type column
        { wch: 15 }, // Width of State column
        { wch: 20 }, // Width of Patient Name column
        { wch: 20 }, // Width of Last Name column
        { wch: 15 }, // Width of Date of Birth column
        { wch: 15 }, // Width of Date of Service column
        { wch: 15 }, // Width of CPT Code column
        { wch: 15 }, // Width of DX Code column
        { wch: 15 }, // Width of Admission column
        { wch: 20 }, // Width of Reason for Visit column
        { wch: 20 }, // Width of Comment column
        { wch: 15 }, // Width of Modifier column
        { wch: 15 }, // Width of Status column
        { wch: 20 }, // Width of Biller column
      ];

      ws["!cols"] = columnWidths;

      // Add worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, "SelectedRows");

      // Save the workbook as an Excel file
      XLSX.writeFile(wb, "Reports.xlsx");
      setSelectAll(false);
      setSelectedRows([]);
    }

    // Filter rows based on selectedRows array
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  return (
    <Box sx={{ px: 10, py: 2 }}>
      <Typography
        variant="h6"
        sx={{ color: "#0093AF", fontWeight: "bold", mb: 2 }}
      >
        Billing Report
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={3} xs={6}>
          <InputLabel id="facility-name-label">Facility Name</InputLabel>
          <Autocomplete
            className="scrollBar"
            fullWidth
            value={searchFacility}
            onChange={(event, newValue) => {
              setSearchFacility(newValue);
              setCurrentPage(1);
            }}
            sx={{ fontSize: "12px !important" }}
            // clearIcon={null}
            options={facilityList} // Pass the array directly
            getOptionLabel={(option) => option.facility_name}
            getOptionSelected={(option, value) =>
              option.facility_name === value.facility_name
            }
            isOptionEqualToValue={(option, value) =>
              option.facility_name === value.facility_name
            }
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  "& input": {
                    height: "0px",
                    fontSize: "14px",
                    fontSize: "12px !important",
                  },
                }}
              />
            )}
          />
        </Grid>

        <Grid item md={2} xs={6}>
          <InputLabel id="facility-type-label">Facility Type</InputLabel>
          <Autocomplete
            className="scrollBar"
            fullWidth
            value={searchFacilityType}
            onChange={(event, newValue) => {
              setSearchFacilityType(newValue);
              setCurrentPage(1);
            }}
            sx={{ fontSize: "12px !important" }}
            // clearIcon={null}
            options={facilityTypeList}
            getOptionLabel={(option) => option.facility_type_Name || ""}
            getOptionSelected={(option, value) => option.facility_type_Name}
            isOptionEqualToValue={(option, value) => option.facility_type_Name}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  "& input": { height: "0px", fontSize: "12px !important" },
                }}
              />
            )}
          />
        </Grid>

        <Grid item md={2} xs={6}>
          <InputLabel id="provider-label">Provider</InputLabel>
          <Autocomplete
            className="scrollBar"
            fullWidth
            value={searchProvider}
            onChange={(event, newValue) => {
              setSearchProvider(newValue);
              setCurrentPage(1);
            }}
            // sx={{ width: '15vw !important' }}
            // clearIcon={null}
            options={providerList}
            getOptionLabel={(option) =>
              option.First_Name + " " + option.Last_Name
            }
            getOptionSelected={(option, value) => option.id === value.id}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  "& input": { height: "0px", fontSize: "12px !important" },
                }}
              />
            )}
          />
        </Grid>

        <Grid item md={2.5} xs={6}>
          <InputLabel id="facility-type-label">Date From / DoS</InputLabel>
          {/* <DatePicker
            // placeholderText='Select Start Date'
            selected={startDate}
            onChange={(date) => {
              setDateRange([date, endDate]);
              setCurrentPage(1);
            }}
            isClearable={true}
            className="reactDateRangePicker"
            selectsStart
            startDate={startDate}
            endDate={endDate}
          /> */}
         <DatePicker
            dateFormat="MM/dd/yyyy"
            selected={startDate}
            onChange={(date) => {
              setDateRange([date, endDate]);
              setCurrentPage(1);
            }}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            // selectsRange
            isClearable
            // placeholderText="Select date range"
            customInput={<TextField fullWidth sx={{ background: "#fff" }} />}
          />
        </Grid>

        <Grid item md={2.5} xs={6}>
          <InputLabel id="facility-type-label">Date To / DoS</InputLabel>
          {/* <DatePicker
            // placeholderText='Select End Date'
            selected={endDate}
            onChange={(date) => {
              setDateRange([startDate, date]);
              setCurrentPage(1);
            }}
            isClearable={true}
            className="reactDateRangePicker"
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          /> */}

          <DatePicker
            dateFormat="MM/dd/yyyy"
            selected={endDate}
            onChange={(date) => {
              setDateRange([startDate, date]);
              setCurrentPage(1);
            }}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            // selectsRange
            isClearable
            // placeholderText="Select date range"
            customInput={<TextField fullWidth sx={{ background: "#fff" }} />}
          />
        </Grid>

        <Grid item md={12}>
          <Grid container spacing={2}>
            <Grid item md={2} xs={6}>
              <InputLabel className="fs16">Patient First Name</InputLabel>
              <TextField
                value={searchFirstName}
                onChange={(event) => {
                  setSearchFirstName(event.target.value);
                  setCurrentPage(1);
                }}
              />
            </Grid>

            <Grid item md={2} xs={6}>
              <InputLabel>Patient Last Name</InputLabel>
              <TextField
                value={searchLastName}
                onChange={(event) => {
                  setSearchLastName(event.target.value);
                  setCurrentPage(1);
                }}
              />
            </Grid>
            <Grid item md={2} xs={6}>
              <InputLabel>Status</InputLabel>
              <Select
                fullWidth
                value={status}
                onChange={(event) => {
                  setStatus(event.target.value);
                  setCurrentPage(1);
                }}
                sx={{ height: "32px" }}
              >
                <MenuItem value={""} sx={{ fontSize: "12px !important" }}>
                  All
                </MenuItem>
                {["Pending", "Confirmed"].map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    sx={{ fontSize: "12px !important" }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ float: "right", mt: 3 }}>
                <Button
                  className="buttonPadding"
                  variant="contained"
                  onClick={handleSearch}
                  sx={{
                    background: "#0093AF !important",
                    boxShadow: "none !important",
                    mr: 1,
                  }}
                >
                  Search
                </Button>
                <Button
                  className="buttonPadding"
                  variant="contained"
                  onClick={handleClearSearch}
                  sx={{
                    background: "#0093AF !important",
                    boxShadow: "none !important",
                    mr: 1,
                  }}
                >
                  Clear
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {loading ? (
        <Box
          sx={{
            minHeight: "40vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress sx={{ color: "#0093AF !important" }} />
        </Box>
      ) : filteredData.length === 0 ? (
        <Typography
          variant="body2"
          sx={{ textAlign: "center", color: "#555", mt: 15 }}
        >
          No Data Available
        </Typography>
      ) : (
        <Box sx={{ my: 4 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
            <Typography
              variant="h6"
              sx={{ color: "#0093AF", fontWeight: "bold" }}
            >
              Reports
            </Typography>
            <Button
              disabled={selectedRows.length === 0}
              variant="contained"
              onClick={handleDownloadExcel}
              sx={{
                boxShadow: "none !important",
                background:
                  selectedRows.length === 0 ? "#d2d2d2" : "#0093AF !important",
              }}
            >
              Download Excel
            </Button>
          </Box>
          <Typography sx={{ fontWeight: 600 }}>
            Number of Encounters: {counts}
          </Typography>
          <TableContainer
            className="scrollBar table-container"
            component={Paper}
          >
            <Table className="custom-table  ">
              <TableHead sx={{ backgroundColor: "#0093AF" }}>
                <TableRow>
                  <TableCell sx={{ color: "#fff", py: 0.5 }}>
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                      style={{ color: "#fff" }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    Provider
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    Facility
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    Facility Type
                  </TableCell>
                  {/* <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }}>State</TableCell> */}
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    First Name
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    Last Name
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    Date of Birth
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    Date of Service
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    Date of Submission
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    CPT Code
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    DX Code
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    Admission
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    Reason for Visit
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    Comment
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    Modifier
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff", py: 1, whiteSpace: "nowrap" }}
                  >
                    Biller
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(filteredData ? filteredData?.reverse() : []).map(
                  (row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ py: 0.5 }}>
                        <Checkbox
                          size="small"
                          checked={selectedRows.includes(row.id)}
                          onChange={() => handleCheckboxChange(row.id)}
                          style={{ color: "#0093AF" }}
                        />
                      </TableCell>
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.provider_profile?.First_Name +
                          " " +
                          row.provider_profile?.Last_Name}
                      </TableCell>
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.facility_master?.facility_name}
                      </TableCell>
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.facility_type?.facility_type_Name}
                      </TableCell>
                      {/* <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.state?.state_Name}</TableCell> */}
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.patient_name}
                      </TableCell>
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.last_name}
                      </TableCell>
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.dob}
                      </TableCell>
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.date_of_service}
                      </TableCell>
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.submitted_on}, {row?.time}
                      </TableCell>
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.cpt_code}
                      </TableCell>
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.dx_code}
                      </TableCell>
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.admission}
                      </TableCell>
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.reason_for_visit}
                      </TableCell>
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.comment}
                      </TableCell>
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.modifier}
                      </TableCell>
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.is_status}
                      </TableCell>
                      <TableCell className="fs12" sx={{ whiteSpace: "nowrap" }}>
                        {row?.biller_profile !== null
                          ? row?.biller_profile?.First_Name +
                            " " +
                            row?.biller_profile?.Last_Name
                          : ""}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <CustomPagination
            count={Math.ceil(counts / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
          />
        </Box>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleEditClose}
      >
        <MenuItem onClick={() => handleEdit("Active")}>Active</MenuItem>
        <MenuItem onClick={() => handleEdit("Inactive")}>Inactive</MenuItem>
      </Menu>
    </Box>
  );
};

export default AdminReportBody;
