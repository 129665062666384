// Import necessary libraries
import React, { useEffect, useRef, useState } from 'react';
import ProviderLayout from '../ProviderLayout';
import { Backdrop, Box, Button, CircularProgress, Grid, InputLabel, TextField, Typography } from '@mui/material';
import Chart from 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import Layout from '../Layout';
import { useDispatch } from 'react-redux';
import { getProviderGraph, getPatientSeenGraph, providermonthlyCount } from '../api/action';
import DatePicker1 from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs';
import FacilityGraph from './Provider/Graph/FacilityGraph';
import EncountersByCpt from './Provider/Graph/EncountersByCpt';

const DashboardProvider = () => {

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [cpt, setCpt] = useState([]);
  const [patientSeen, setPatientSeen] = useState([]);
  const [months, setMonths] = useState([]);
  const [counts, setCounts] = useState([]);
  const [loading, setLoading] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const id = localStorage.getItem("userId")
  const currentYear = dayjs().startOf('year');
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const formatYear = (date) => date ? date.year() : '';

  console.log(currentYear, "currentYear")

  const adjustToLocalDate = (date) => {
    if (date === null) return null;
    const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return adjustedDate.toISOString().split('T')[0];
  };


  useEffect(() => {
    setLoading(true)
    const payload = {
      start_date: adjustToLocalDate(startDate),
      end_date: adjustToLocalDate(endDate),
      user_id: id
    };
    dispatch(
      getProviderGraph(payload,
        (data) => {
          setLoading(false);
          setData(data?.data?.Data)
          setCpt(data?.data?.Data?.cpt)
          setPatientSeen(data?.data?.Data?.patientdata)

        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
    );

  }, [endDate])

  // useEffect(() => {
  //   setLoading(true)
  //   const payload = {
  //     provider_id: localStorage.getItem("profileId"),
  //     year_value: formatYear(selectedYear)
  //   };
  //   dispatch(
  //     providermonthlyCount(payload,
  //       (data) => {
  //         console.log(data, "data")
  //         setLoading(false);
  //         // setData(data?.data?.Data)
  //         // setCpt(data?.data?.Data)
  //         setCounts(data?.data?.months_count)
  //         setMonths(data?.data?.months_range)

  //       },
  //       (error) => {
  //         console.log(error)
  //         setLoading(false)
  //       }
  //     )
  //   );

  // }, [selectedYear])

  // useEffect(() => {
  //   setLoading(true)
  //   const payload = null;
  //   dispatch(
  //     getPatientSeenGraph(payload,
  //       (data) => {
  //         setPatientSeen(data?.data)
  //         setLoading(false);
  //       },
  //       (error) => {
  //         console.log(error)
  //         setLoading(false)
  //       }
  //     )
  //   );

  // }, [])

  // Corrected the chart refs
  const chart1Ref = useRef(null);
  const chart2Ref = useRef(null);

  const chartData1 = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: 'Patient Seen',
        data: [patientSeen?.Week_1, patientSeen?.Week_2, patientSeen?.Week_3, patientSeen?.Week_4],
        // backgroundColor: '#ADD8E6',
        // borderColor: '#ADD8E6',
        borderWidth: 1,
        // pointBackgroundColor: ['#b9ffad', '#cec8ff', '#ffefc8', '#ffadad'], 
        // pointBorderColor: '#000', 
        // pointBorderWidth: 1, 
      },
    ],
  };

  const chartData2 = {
    labels: cpt?.map(item => item["CPT Code"]), // Extracting CPT Codes as labels
    datasets: [
      {
        data: cpt?.map(item => item.Count), // Extracting Count as data
        // backgroundColor: ['#b9ffad', '#cec8ff', '#ffefc8'], // Uncomment this line if you want to set background colors
      },
    ],
  };

  useEffect(() => {
    if (!chart1Ref.current) {
      chart1Ref.current = new Chart(document.getElementById('myChart1').getContext('2d'), {
        type: 'line',
        data: chartData1,
        options: {
          scales: {
            x: {
              title: {
                display: true,
                text: 'Week',
              },
              grid: {
                display: true, // Remove grid lines from x-axis
              },
            },
            y: {
              title: {
                display: true,
                text: 'Number of Patients Seen',
              },
              beginAtZero: true,
              grid: {
                display: true, // Remove grid lines from y-axis
              },
            },
          },
        },
      });
    }

    if (!chart2Ref.current) {
      chart2Ref.current = new Chart(document.getElementById('myChart2').getContext('2d'), {
        type: 'doughnut',
        data: chartData2,
        options: {
          cutout: '50%',
          legend: {
            labels: {
              font: {
                weight: 'bold',
                size: 24,
              },
            },
          },
          maintainAspectRatio: true,
          aspectRatio: 2,
          height: "1000px",
        },
      });
    }

    return () => {
      if (chart1Ref.current) {
        chart1Ref.current.destroy();
        chart1Ref.current = null;
      }
      if (chart2Ref.current) {
        chart2Ref.current.destroy();
        chart2Ref.current = null;
      }
    };
  }, [chartData1, chartData2]);

  const handleClearSearch = () => {
    setStartDate(null)
    setEndDate(null)
  };

  console.log(formatYear(selectedYear), "data")



  return (
    <ProviderLayout>
      <Grid container sx={{ px: 10, mt: 4 }}>
        <Grid item md={3} xs={6}>
          <InputLabel id="facility-type-label">Date From </InputLabel>
          <DatePicker1
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            isClearable={true}
            className='reactDateRangePicker1'
          />
        </Grid>

        <Grid item md={1} xs={6}>

        </Grid>

        <Grid item md={3} xs={6}>
          <InputLabel id="facility-type-label">Date To </InputLabel>
          <DatePicker1
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            isClearable={true}
            className='reactDateRangePicker1'
            sx={{ width: "20rem !important" }}
          />
        </Grid>


        <Grid item md={1} xs={6}>

        </Grid>

        <Grid item md={2}>
          <Button
            className='buttonPadding'
            variant="contained"
            onClick={handleClearSearch}
            sx={{ background: '#0093AF !important', boxShadow: 'none !important', mt: 2.5 }}
          >
            Clear
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={5} sx={{ my: 2, px: 10 }}>

        <Grid item md={6} xs={12} sx={{ textAlign: 'center', border: '2px solid #0093AF1a', borderRadius: '6px', pt: 5, pb: 2, px: 5, }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography className='fs16 textColorDark' sx={{ fontWeight: 'bold' }}>
              Number of Patients
            </Typography>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={['year']}
                openTo="year"
                renderInput={(params) => <TextField {...params} />}
                sx={{ width: '8rem !important' }}
                maxDate={dayjs().startOf('year')}
                value={selectedYear}
                onChange={(newValue) => setSelectedYear(newValue)}
              />
            </LocalizationProvider> */}
          </Box>
          <canvas id="myChart1" width="400" height="200"></canvas>

        </Grid>


        <Grid item md={6} xs={12} sx={{ textAlign: 'center' }}>
          <Box sx={{ textAlign: 'center', border: '2px solid #0093AF1a', borderRadius: '6px', pt: 5, pb: 2, px: 5, position: "relative" }}>
            <Typography className='fs16 textColorDark' sx={{ fontWeight: 'bold' }}>
              Number of Encounter
            </Typography>
            <canvas id="myChart2" width="400" height="200"></canvas>
          </Box>
        </Grid>

        <Grid item md={6} xs={12} sx={{ textAlign: 'center' }}>
          <FacilityGraph />
        </Grid>

        <Grid item md={6} xs={12} sx={{ textAlign: 'center' }}>
          <EncountersByCpt />
        </Grid>

      </Grid>

      {loading ?
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress sx={{ color: '#0093af' }} />
        </Backdrop>
        : ""}
    </ProviderLayout>
  );
};

export default DashboardProvider;
