import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Divider, Box } from '@mui/material';

const InformationModal = ({ open, setOpen }) => {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ backgroundColor: '#0093AF', color: '#fff'}}>Excel Upload Guidelines</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2, mt:5 }}>
          <Typography variant="subtitle1" gutterBottom>Supported File Type:</Typography>
          <Typography variant="body2" color="textSecondary">Only Excel files (.xlsx format) are supported.</Typography>
        </Box>
        
        <Divider />

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>Required Headers:</Typography>
          <Typography variant="body2" color="textSecondary">Ensure the following column headers are present in the Excel sheet:</Typography>
          <ul style={{ marginTop: '0.5rem', marginLeft: '1.5rem' }}>
            {['Facility Type', 'Facility Name', 'Provider Email', 'Provider First Name', 'Provider Last Name', 'Patient First Name', 'Patient Last Name', 'Date of Birth (DOB)', 'Date of Service (DOS)', 'CPT Code', 'DX Code', 'Modifier', 'Admission', 'Comment', 'Reason for Visit'].map(header => (
              <li key={header}>
                <Typography variant="body2" color="textPrimary">{header}</Typography>
              </li>
            ))}
          </ul>
        </Box>

        <Divider sx={{ mt: 2, mb: 2 }} />

        <Box>
          <Typography variant="subtitle1" gutterBottom>Unexpected Column Headers:</Typography>
          <Typography variant="body2" color="textSecondary">
            Only the exact headers mentioned above should be present in the file. If extra columns are found, the system will reject the upload and return an error indicating the unexpected columns.
          </Typography>
        </Box>

        <Divider sx={{ mt: 2, mb: 2 }} />

        <Box>
          <Typography variant="subtitle1" gutterBottom>Validations:</Typography>
          <ul style={{ marginTop: '0.5rem', marginLeft: '1.5rem' }}>
            {[
              { title: 'Empty Rows:', desc: 'Any empty row will trigger an error specifying the row number.' },
              { title: 'Provider Email Validation:', desc: 'Ensure the email matches the registered provider\'s email, otherwise, the system will reject the upload.' },
              { title: 'Facility Type and Name Validation:', desc: 'Both must exist in the system, and their relationship must be valid.' },
              { title: 'CPT Code Validation:', desc: 'When entering CPT codes in the Excel file, it is mandatory to include a space after each CPT code, with each code separated by a comma. Example: 98434, 99883, 99436, 45834' },
              { title: 'Date Validation:', desc: 'Dates must be in YYYY-MM-DD, DD-MM-YYYY, or MM-DD-YYYY format.' },
              { title: 'Case Sensitivity for Email:', desc: 'All emails should be lowercase to avoid errors.' }
            ].map(item => (
              <li key={item.title}>
                <Typography variant="body2" color="textPrimary">
                  <strong>{item.title}</strong> {item.desc}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>

        <Divider sx={{ mt: 2, mb: 2 }} />

        <Box>
          <Typography variant="subtitle1" gutterBottom>Error Messages:</Typography>
          <Typography variant="body2" color="textSecondary">
            The system will return an array of errors, specifying the row number and column where the issue was found.
          </Typography>
        </Box>

        <Divider sx={{ mt: 2, mb: 2 }} />

        <Box>
          <Typography variant="subtitle1" gutterBottom>Date Fields:</Typography>
          <Typography variant="body2" color="textSecondary">
            If "Submitted On" or "Time" columns are missing, the system will auto-fill them with the current date and time.
          </Typography>
        </Box>

        <Divider sx={{ mt: 2, mb: 2 }} />

        <Box>
          <Typography variant="subtitle1" gutterBottom>Successful Upload:</Typography>
          <Typography variant="body2" color="textSecondary">
            If all validations pass, the data will be uploaded, and a success message will be returned.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: '#0093AF', '&:hover': { backgroundColor: '#007B92' } }}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InformationModal;
