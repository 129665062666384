// Import necessary libraries
import React, { useEffect, useRef, useState } from 'react';
import ProviderLayout from '../ProviderLayout';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import FacilityGraph from './Provider/Graph/FacilityGraph';
import EncountersByCpt from './Provider/Graph/EncountersByCpt';

const DashboardProvider1 = () => {
  const [loading, setLoading] = useState('');

  return (
    <ProviderLayout>
      <Grid container >

        <Grid item md={6} xs={12} sx={{ textAlign: 'center', p:2 }}>
          <FacilityGraph />
        </Grid>

        <Grid item md={6} xs={12} sx={{ textAlign: 'center', p:2 }}>
          <EncountersByCpt />
        </Grid>

      </Grid>

      {loading ?
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress sx={{ color: '#0093af' }} />
        </Backdrop>
        : ""}
    </ProviderLayout>
  );
};

export default DashboardProvider1;
