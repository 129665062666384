// Import necessary libraries
import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, TextField, Typography, Autocomplete, Paper, ListItemText, Checkbox, FormControlLabel, Chip } from '@mui/material';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the plugin
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import { facilityGraph, getAllFacility } from '../../../../api/action';
import CustomSnackbar from '../../../../Utils/CustomSnackbar';

const EncountersByFacility = () => {
    // Bar chart ref
    const chartRef = useRef(null);
    const [dateRange, setDateRange] = useState([null, null]); // Array to store start and end dates
    const [startDate, endDate] = dateRange;
    const [data, setData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [loading, setLoading] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch = useDispatch();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');

    const adjustToLocalDate = (date) => {
        if (date === null) return null;
        const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        return adjustedDate.toISOString().split('T')[0];
    };

    useEffect(() => {
        setLoading(true);
        const payload = null;
        dispatch(
            getAllFacility(payload,
                (data) => {
                    setLoading(false);
                    setData(data?.data?.Data);
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            )
        );
    }, [dispatch]);

    useEffect(() => {
        setLoading(true);
        const payload = {
            "facility_id": selectedOption?.id,
            // start_date: adjustToLocalDate(startDate),
            // end_date: adjustToLocalDate(endDate),
        };
        dispatch(
            facilityGraph(payload,
                (data) => {
                    setLoading(false);
                    setGraphData(data?.data);
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            )
        );
    }, [selectedOption, endDate, dispatch]);

    const truncateLabel = (label) => {
        const maxLength = 6; // Set your desired max length
        return label.length > maxLength ? `${label.substring(0, maxLength)}...` : label;
    };

    // Chart data
    const chartData = {
        labels: graphData.map(item => truncateLabel(item.month)),
        datasets: [
            {
                data: graphData.map(item => item.count),
                backgroundColor: '#4bc0c0', // Primary color for bars
                borderColor: '#4bc0c0',
                borderWidth: 2,
                hoverBackgroundColor: '#4bc0c0', // Hover color for bars
                hoverBorderColor: '#4bc0c0',
                barThickness: 25,
            },
        ],
    };

    // Initialize or update chart
    useEffect(() => {
        if (!chartRef.current) {
            const maxDataValue = Math.max(...graphData.map(item => item.count));
            const dynamicStepSize = Math.ceil(maxDataValue / 5);
            const suggestedMax = Math.ceil(maxDataValue / dynamicStepSize) * dynamicStepSize + dynamicStepSize;

            chartRef.current = new Chart(document.getElementById('myBarChart2').getContext('2d'), {
                type: 'bar',
                data: chartData,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    indexAxis: 'x', // Switch to vertical bar chart
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            backgroundColor: '#4bc0c0',
                            titleFont: { size: 14, weight: 400 },
                            bodyFont: { size: 14 },
                            bodyColor: '#fff',
                            borderColor: '#4bc0c0',
                            borderWidth: 1,
                            // callbacks: {
                            //     label: (tooltipItem) => {
                            //         const index = tooltipItem.dataIndex;
                            //         return `${graphData[index].facility_name}: ${tooltipItem.raw}`;
                            //     },
                            // },
                        },
                        datalabels: {
                            color: '#007B89',
                            font: { weight: 400 },
                            anchor: 'end',
                            align: 'top',
                            formatter: (value) => value,
                        },
                    },
                    scales: {
                        x: {
                            grid: { display: false },
                            title: {
                                display: true,
                                text: 'Months',
                                color: '#333',
                                font: { size: 12, weight: 600 },
                            },
                            ticks: {
                                color: '#333',
                            },
                        },
                        y: {
                            grid: { color: '#ddd' },
                            title: {
                                display: true,
                                text: 'No. of Encounters',
                                color: '#333',
                                font: { size: 12, weight: 400 },
                            },
                            ticks: {
                                beginAtZero: true,
                                color: '#333',
                                stepSize: dynamicStepSize, // Use the dynamically calculated step size
                            },
                            suggestedMax: suggestedMax, // Suggest a max value one step above the highest value
                        },
                    },
                },
                plugins: [ChartDataLabels],
            });
        } else {
            chartRef.current.data = chartData;
            chartRef.current.update();
        }

        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
                chartRef.current = null;
            }
        };
    }, [chartData]);


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };


    return (
        <Paper sx={{ p: 5, bgcolor: '#f9f9f9' }}>
            <CustomSnackbar
                open={snackbarOpen}
                handleClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
            <Grid container spacing={4}>
                {/* Autocomplete */}
                <Grid item xs={12}>
                    <Autocomplete
                        // multiple
                        // limitTags={1}
                        // size={"100px"}
                        // disableCloseOnSelect
                        value={selectedOption}
                        onChange={(event, newValue) => {
                            setSelectedOption(newValue);
                        }}
                        // value={selectedOption?.map(id => data.find(option => option.id === id))}
                        // onChange={(event, newValue) => {
                        //     if (newValue.length <= 4) {
                        //         setSelectedOption(newValue.map(option => option.id));
                        //     } else {
                        //         setSnackbarMessage("You can select only 4 Facilities");
                        //         setSnackbarSeverity("error");
                        //         setSnackbarOpen(true);
                        //     }
                        // }}
                        // renderTags={(value, getTagProps) =>
                        //     value.map((option, index) => {
                        //         const { key, ...tagProps } = getTagProps({ index });
                        //         return (
                        //             <Chip
                        //                 key={key}
                        //                 //   variant="outlined"
                        //                 label={option.facility_name} // Use `facility_name` from your options
                        //                 size="small"
                        //                 sx={{ fontSize: '10px',  margin:0, paddingRight:'0px', width:'7rem', height:'20px'}}
                        //                 {...tagProps}
                        //             />
                        //         );
                        //     })
                        // }
                        sx={{
                            // maxHeight: 56,
                            // height: 56,
                            padding: 0,
                            fontSize: "10px",
                            overflowY: 'hidden',
                            '& .MuiAutocomplete-inputRoot': {
                                flexWrap: 'nowrap', // Prevent wrapping of chips
                                fontSize: '10px',
                                // padding: 0,
                                px: 0,
                                py: 0
                            },
                            '& .MuiAutocomplete-tag': {
                                overflow: 'hidden',
                                padding: 0,

                            }
                        }}
                        ListboxProps={{
                            style: {
                                maxHeight: '150px',
                                overflowY: 'auto',
                            }
                        }}
                        options={data}
                        getOptionLabel={(option) => option.facility_name}
                        // getOptionDisabled={(option) => selectedOption?.length >= 3 && !selectedOption?.includes(option.id)}
                        renderInput={(params) => (
                            <TextField
                                placeholder="Select Facility"
                                {...params}
                                fullWidth
                                sx={{
                                    background: "#fff",
                                    '& input': {
                                        height: "17px",
                                        padding: '0px',
                                        fontSize: '12px',
                                        overflowY: 'scroll',
                                        '&::-webkit-scrollbar': {
                                            display: 'none',
                                        },
                                    },
                                }}
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    // renderOption={(props, option, { selected }) => (
                    //     <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                    //         <FormControlLabel
                    //             sx={{ fontSize: "10px !important" }}
                    //             control={
                    //                 <Checkbox
                    //                     sx={{
                    //                         transform: 'scale(0.75)',
                    //                         '& .MuiSvgIcon-root': { fontSize: 22 },
                    //                     }}
                    //                     checked={selected}
                    //                     disabled={!selected && selectedOption?.length >= 3}
                    //                 />
                    //             }
                    //             label={
                    //                 <ListItemText
                    //                     sx={{
                    //                         '& .MuiTypography-root': { fontSize: '10px !important' }
                    //                     }}
                    //                     primary={`${option.facility_name}`}
                    //                 />
                    //             }
                    //         />
                    //     </li>
                    // )}
                    />

                </Grid>

                {/* <Grid item xs={6}>
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        selected={startDate}
                        onChange={(dates) => setDateRange(dates)}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        isClearable
                        placeholderText="Select date range"
                        customInput={<TextField fullWidth className='width-100' sx={{ background: "#fff", }} />}
                    />
                </Grid> */}

                {/* Horizontal Bar Chart */}
                <Grid item xs={12}>
                    <Paper elevation={0} sx={{ borderRadius: '16px', px: 3, py: 3 }}>
                        <Typography variant="body1" gutterBottom align="center" sx={{ color: '#4bc0c0' }}>
                            Encounters by Facility
                        </Typography>
                        <Box sx={{ height: '300px', bgcolor: '#fff', borderRadius: '16px' }}>
                            <canvas id="myBarChart2"></canvas>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default EncountersByFacility;
